@import "~react-image-gallery/styles/css/image-gallery.css";
video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

html,
body {
  height: 100%;
  color: white;
}

body {
  margin: 0;
  /*            REMOVE THIS FOR VIDEO*/
  background-image: url("/public/assets/images/13.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /*            REMOVE THIS FOR VIDEO*/
}

.viewport-header {
  position: relative;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

main {
  background: rgba(black, 0.66);
  
  position: relative;
  padding: 1rem;
}

